import sthumb1 from "../assets/images/conference.jpg";
// import sthumb2 from "../assets/images/blog2.jpeg";
import sthumb3 from "../assets/images/blog3.jpg";
import sthumb4 from "../assets/images/blog4.png";
import sthumb5 from "../assets/images/blog5.png";
import thumb1 from "../assets/images/beach_cleanup/4.jpg";

import thumb2 from "../assets/images/ists_thumb.jpg";
// import thumb3 from "../assets/images/blog3.jpg";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function BlogsDisplay() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div className="pt-14 md:pt-16 lg:pt-32">
        <div className="container">
          <div data-aos="fade-down">
            <h6 className="text-center text-xs md:text-sm mini-heading mb-1">
              WHAT&apos;S NEW
            </h6>
            <h3
              data-aos="fade-up"
              data-aos-delay="100"
              className="text-center main-heading font-medium text-2xl md:text-4xl mb-14"
            >
              Our News & Events
            </h3>
          </div>
          <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
            <div
              data-aos="fade-right"
              data-aos-delay="200"
              className="w-full flex flex-col md:flex-row gap-y-10 lg:gap-y-0"
            >
              <div className="basis-full md:basis-1/2 md:px-5">
                <Link to={"/blog8"}>
                  <img alt="beach cleanup" src={thumb2} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      EVENT
                    </span>
                    <h6 className="ml-5">2024</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                  Join the 43rd International Sea Turtle Society Symposium
                  </h4>
                </Link>
              </div>
              <div className="basis-full md:basis-1/2 md:px-5">
                <Link to={"/blog7"}>
                  <img alt="beach cleanup" src={thumb1} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      MEDIA
                    </span>
                    <h6 className="ml-5">2024</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    WHRO Celebrates World Oceans Day 2024 with Awareness
                    Creation and Beach Cleanup
                  </h4>
                </Link>
              </div>
              {/* <div className="basis-full md:basis-1/2 md:px-5">
                <Link to={"/blog"}>
                  <img alt="sea turtle conference" src={sthumb1} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      EVENT
                    </span>
                    <h6 className="ml-5">2023</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    2nd Ghana National Sea Turtle Conference 2023
                  </h4>
                </Link>
              </div> */}
              {/* <div className="basis-full md:basis-1/2 md:px-5">
                <Link to={"/blog4"}>
                  <img alt="beach cleanup" src={sthumb4} className="mb-6" />
                  <div className="flex items-center mb-2">
                    <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                      MEDIA
                    </span>
                    <h6 className="ml-5">2023</h6>
                  </div>
                  <h4 className="text-lg md:text-xl font-semibold">
                    WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                    Turtles
                  </h4>
                </Link>
              </div> */}
            </div>
            <div
              data-aos="fade-left"
              data-aos-delay="200"
              className="lg:w-2/3 xl:w-1/3 md:px-5"
            >
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog4">
                  <div className="w-1/4">
                    <img alt="sea turtle conservation efforts" src={sthumb4} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                      Turtles
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog5">
                  <div className="w-1/4">
                    <img alt="Anyanui Mangrove Market" src={sthumb5} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s
                      Visit to Anyanui Mangrove Market
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog3">
                  <div className="w-1/4">
                    <img alt="sea turtle conservation discussion" src={sthumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Experts Discuss Urgent Need for Sea Turtle Conservation in
                      Ghana
                    </h4>
                  </div>
                </Link>
              </div>
              {/* <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog2">
                  <div className="w-1/4">
                    <img alt="beach cleanup" src={sthumb2} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                      Resources Conservation’
                    </h4>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
          <div data-aos="fade-up" className="flex w-full justify-center pt-10">
            <Link to="/blogs">
              <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                View All News
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogsDisplay;
