import { useState, useEffect } from "react";
import popup from "/images/ists.jpg";
import close from "../assets/images/x-black_lg.svg";
import { Link } from "react-router-dom";

function PopUp() {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

// Use an effect to handle the popup show logic if necessary
// useEffect(() => {
// Here you could add logic to show the popup only after certain conditions are met
// }, []);

if (!isVisible) return null;

return (
<div className="w-screen h-screen bg-black bg-opacity-80 fixed top-0 z-[999] flex justify-center items-center">
  <div className="md:w-1/2 ll:w-2/5 flex flex-col bg-white">
    <div className="xl:w-full relative bg-white">
      <img
        className="absolute right-2 top-2 cursor-pointer"
        src={close}
        alt="close button"
        onClick={handleClose}
      />
      <img src={popup} alt="popup content" />
      <div className="w-full flex justify-center py-4">
        {/* <a
          href="https://www.ists-symposium43.org/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClose}
        > */}
          <Link to={'/blog8'}>
          <button className="bg-green-900 hover:bg-green-700 text-footer-white py-1 px-3 rounded-sm w-36 mx-auto lg:text-lg">
            Register Now
          </button></Link>
        {/* </a> */}
      </div>
    </div>
  </div>
</div>
  );
}

export default PopUp;
