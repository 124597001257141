import React from "react";
import ReactDOM from "react-dom/client";

// import App from "./App.jsx";
import "./assets/styles/index.css";
import Home from "./pages/Home.jsx";
import Team from "./pages/Team.jsx";
// import Nav from "./components/Nav.jsx";
// import Footer from "./components/Footer.jsx";
import Volunteer from "./pages/Volunteer.jsx";
import About from "./pages/About.jsx";
import Contact from "./pages/Contact.jsx";
// import About2 from "./pages/About2.jsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root.jsx";
import Error from "./pages/Error.jsx";
import Blog from "./pages/Blog.jsx";
import BlogList from "./pages/BlogList.jsx";
import GalleryList from "./pages/GalleryList.jsx";
import YspGallery from "./pages/YspGallery.jsx";
import EducationGallery from "./pages/EducationGallery.jsx";
import ShoreBirdsGallery from "./pages/ShoreBirdsGallery.jsx";
import VulturesGallery from "./pages/VultureGallery.jsx";
import Projects from "./pages/Projects.jsx";
import TeamProfile1 from "./pages/TeamProfile1.jsx";
import TeamProfile2 from "./pages/TeamProfile2.jsx";
import TeamProfile3 from "./pages/TeamProfile3.jsx";
import TeamProfile4 from "./pages/TeamProfile4.jsx";
import TeamProfile5 from "./pages/TeamProfile5.jsx";
import TeamProfile6 from "./pages/TeamProfile6.jsx";
import TeamProfile7 from "./pages/TeamProfile7.jsx";
import TeamProfile8 from "./pages/TeamProfile8.jsx";
import TeamProfile9 from "./pages/TeamProfile9.jsx";
import TeamProfile10 from "./pages/TeamProfile10.jsx";
import TeamProfile11 from "./pages/TeamProfile11.jsx";
import TeamProfile12 from "./pages/TeamProfile12.jsx";
import Services from "./pages/Services.jsx";
import PubList from "./pages/PubList.jsx";
// import Programmes from "./pages/Programmes.jsx";
import Pub1 from "./pages/Pub1.jsx";
import Pub2 from "./pages/Pub2.jsx";
import Pub3 from "./pages/Pub3.jsx";
import Pub4 from "./pages/Pub4.jsx";
import Pub5 from "./pages/Pub5.jsx";
import Pub6 from "./pages/Pub6.jsx";
import Pub7 from "./pages/Pub7.jsx";
import Pub8 from "./pages/Pub8.jsx";
import Pub9 from "./pages/Pub9.jsx";
import Pub10 from "./pages/Pub10.jsx";
import Pub11 from "./pages/Pub11.jsx";
import Pub12 from "./pages/Pub12.jsx";
import Pub13 from "./pages/Pub13.jsx";
import Pub14 from "./pages/Pub14.jsx";
import Pub15 from "./pages/Pub15.jsx";
import Pub16 from "./pages/Pub16.jsx";
import Pub17 from "./pages/Pub17.jsx";
import Pub18 from "./pages/Pub18.jsx";
import TurtlesGallery from "./pages/TurtlesGallery.jsx";
import InternsGallery from "./pages/InternsGallery.jsx";
import EMonitoringGallery from "./pages/EMonitoringGallery.jsx";
import MangroveGallery from "./pages/MangroveGallery.jsx";
import TrainingGallery from "./pages/TrainingGallery.jsx";
import CommunitySupportGallery from "./pages/CommunitySupportGallery.jsx";
import ForestBirdsGallery from "./pages/ForestBirdsGallery.jsx";
import YSProgrammes from "./pages/YSProgramme.jsx";
import InternshipProgramme from "./pages/InternshipProgramme.jsx";
import SeaTurtleProgramme from "./pages/SeaTurtleProgramme.jsx";
import Blog2 from "./pages/Blog2.jsx";
import MangroveProgramme from "./pages/MangroveProgramme.jsx";
import CommunitySupportProgramme from "./pages/CommunitySupportProgramme.jsx";
import ComingSoon from "./pages/ComingSoon.jsx";
import Blog3 from "./pages/Blog3.jsx";
import Blog4 from "./pages/Blog4.jsx";
import Blog5 from "./pages/Blog5.jsx";
import ProgrammesList from "./pages/ProgrammesList.jsx";
import { HelmetProvider } from "react-helmet-async";
import Donate from "./pages/Donate.jsx";
import BeachCleanupGallery from "./pages/BeachCleanupGallery.jsx";
import Blog7 from "./pages/Blog7.jsx";
import Blog8 from "./pages/Blog8.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "coming-soon",
        element: <ComingSoon />,
      },
      {
        path: "team",
        element: <Team />,
      },
      {
        path: "volunteer",
        element: <Volunteer />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "donate",
        element: <Donate />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "blog2",
        element: <Blog2 />,
      },
      {
        path: "blog3",
        element: <Blog3 />,
      },
      {
        path: "blog4",
        element: <Blog4 />,
      },
      {
        path: "blog5",
        element: <Blog5 />,
      },
      {
        path: "blog7",
        element: <Blog7 />,
      },
      {
        path: "blog8",
        element: <Blog8 />,
      },
      {
        path: "blogs",
        element: <BlogList />,
      },
      {
        path: "publications-list",
        element: <PubList />,
      },
      {
        path: "gallery-list",
        element: <GalleryList />,
      },
      {
        path: "ysp-gallery",
        element: <YspGallery />,
      },
      {
        path: "education-gallery",
        element: <EducationGallery />,
      },
      {
        path: "shore-birds-gallery",
        element: <ShoreBirdsGallery />,
      },
      {
        path: "vultures-gallery",
        element: <VulturesGallery />,
      },
      {
        path: "sea-turtles-gallery",
        element: <TurtlesGallery />,
      },
      {
        path: "interns-gallery",
        element: <InternsGallery />,
      },
      {
        path: "e-monitoring-gallery",
        element: <EMonitoringGallery />,
      },
      {
        path: "mangrove-gallery",
        element: <MangroveGallery />,
      },
      {
        path: "training-gallery",
        element: <TrainingGallery />,
      },
      {
        path: "community-support-gallery",
        element: <CommunitySupportGallery />,
      },
      {
        path: "forest-birds-gallery",
        element: <ForestBirdsGallery />,
      },
      {
        path: "beach-cleanup-gallery",
        element: <BeachCleanupGallery />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "programmes",
        element: <ProgrammesList />,
      },
      {
        path: "ysp",
        element: <YSProgrammes />,
      },
      {
        path: "mangrove-conservation",
        element: <MangroveProgramme />,
      },
      {
        path: "sea-turtles-programme",
        element: <SeaTurtleProgramme />,
      },
      {
        path: "internship-programme",
        element: <InternshipProgramme />,
      },
      {
        path: "community-programme",
        element: <CommunitySupportProgramme />,
      },
      {
        path: "team-profile/1",
        element: <TeamProfile1 />,
      },
      {
        path: "team-profile/2",
        element: <TeamProfile2 />,
      },
      {
        path: "team-profile/3",
        element: <TeamProfile3 />,
      },
      {
        path: "team-profile/4",
        element: <TeamProfile4 />,
      },
      {
        path: "team-profile/5",
        element: <TeamProfile5 />,
      },
      {
        path: "team-profile/6",
        element: <TeamProfile6 />,
      },
      {
        path: "team-profile/7",
        element: <TeamProfile7 />,
      },
      {
        path: "team-profile/8",
        element: <TeamProfile8 />,
      },
      {
        path: "team-profile/9",
        element: <TeamProfile9 />,
      },
      {
        path: "team-profile/10",
        element: <TeamProfile10 />,
      },
      {
        path: "team-profile/11",
        element: <TeamProfile11 />,
      },
      {
        path: "team-profile/12",
        element: <TeamProfile12 />,
      },
      {
        path: "publications/1",
        element: <Pub1 />,
      },
      {
        path: "publications/2",
        element: <Pub2 />,
      },
      {
        path: "publications/3",
        element: <Pub3 />,
      },
      {
        path: "publications/4",
        element: <Pub4 />,
      },
      {
        path: "publications/5",
        element: <Pub5 />,
      },
      {
        path: "publications/6",
        element: <Pub6 />,
      },
      {
        path: "publications/7",
        element: <Pub7 />,
      },
      {
        path: "publications/8",
        element: <Pub8 />,
      },
      {
        path: "publications/9",
        element: <Pub9 />,
      },
      {
        path: "publications/10",
        element: <Pub10 />,
      },
      {
        path: "publications/11",
        element: <Pub11 />,
      },
      {
        path: "publications/12",
        element: <Pub12 />,
      },
      {
        path: "publications/13",
        element: <Pub13 />,
      },
      {
        path: "publications/14",
        element: <Pub14 />,
      },
      {
        path: "publications/15",
        element: <Pub15 />,
      },
      {
        path: "publications/16",
        element: <Pub16 />,
      },
      {
        path: "publications/17",
        element: <Pub17 />,
      },
      {
        path: "publications/18",
        element: <Pub18 />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);
