import sthumb1 from "../assets/images/conference.jpg";
import sthumb2 from "../assets/images/blog2.jpeg";
import sthumb3 from "../assets/images/blog3.jpg";
import sthumb4 from "../assets/images/blog4.png";
import sthumb5 from "../assets/images/blog5.png";

import thumb1 from "../assets/images/beach_cleanup/4.jpg";
import thumb2 from "../assets/images/eaa/05.jpg";
import thumb3 from "../assets/images/beach_cleanup/52.jpg";
import thumb4 from "../assets/images/ists_thumb.jpg";
import facebook from "../assets/images/facebook_g.svg";
import twitter from "../assets/images/twitter_g.svg";
import instagram from "../assets/images/instagram_g.svg";
import { Link } from "react-router-dom";
import MetaTags from "../components/MetaTags";

function Blog8() {
  return (
    <>
      <MetaTags
        title="Blog Page - WHRO"
        keywords="Blog,International,Sea,Turtle,Society,Symposium"
        canonical="https://www.whroghana.org/blog8"
      />
      <div className="team-display-section py-14 md:py-16 lg:pb-32">
        <div className="container">
          <div className="flex flex-wrap box-border md:px-10">
            <div className="w-full xl:w-[70%] xl:px-10 box-border mb-20 xl:mb-0">
              <div className="article">
                <img alt="Join the 43rd International Sea Turtle Society Symposium" src={thumb4} className="mb-6" />
                <div className="flex items-center my-5">
                  <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                    EVENT
                  </span>
                  <h6 className="ml-5">
                    By <span className="publisher">Jennifer Dede Ankrah</span>{" "}
                    published <span className="pub-date">2024</span>
                  </h6>
                </div>
                <h1 className="text-4xl mb-2 font-medium">
                Join the 43rd International Sea Turtle Society Symposium
                </h1>

                <p className="article-par">
                <b>The International Sea Turtle Society Symposium</b> is a global gathering of sea turtle researchers, environmental practitioners, conservationists, students, and advocates, dedicated to advancing sea turtle conservation through science, education, and collaboration. This annual event provides a platform for individuals to share knowledge, present research, and promote partnerships that enhance the protection of sea turtles worldwide.
                </p>
                <p className="article-par"><b>The 43rd edition of the ISTS Symposium</b> is a monumental event as it will be hosted in <b>Africa</b> for the first time in the symposium's history. The event is scheduled for <b>March 22-27, 2025</b>, in <b>Accra, Ghana</b>. It will highlight Africa’s unique role in sea turtle conservation, shedding light on the challenges and opportunities across the continent.</p>
                <p className="article-par">With the theme <b>"Unity and Collaboration"</b>, the symposium will emphasize the need for the global sea turtle community to unite and foster strong and lasting collaborations towards promoting and sustaining healthy populations of sea turtles around the globe.</p>
                <h5 class="text-2xl mb-6 font-medium">Why Attend the 43rd International Sea Turtle Symposium?</h5>
                <p className="article-par">
                Attending the 43rd International Sea Turtle Symposium offers a unparalleled opportunity to connect with sea turtle experts, researchers, conservationists, and students from over 60 countries. Through workshops, networking events, and capacity-building activities, participants will gain insights into global conservation efforts, engage in discussions, and explore collaborative research opportunities. 
                </p>
                <h5 class="text-2xl mb-6 font-medium">What to Expect</h5>
                <ul className="pl-2 list-disc"> 
                  <li><b>Workshops & Regional Meetings:</b> Focus on training, talks, and discussions of activities across different continents.</li>
                  <li><b>Live & Silent Auctions:</b> Fundraising events that support travel grants for future symposium.</li>
                  <li><b>Student Events:</b> Special sessions for students to engage with peers and experts, including activities like Speed Chatting, Student Mixer, and more.</li>
                  <li><b>Video Night:</b> Inspires participants with impactful videos that highlight sea turtle education, research, and conservation efforts.</li>
                  <li><b>Awards:</b> Recognizing outstanding contributions from sea turtle researchers, environmental practitioners, conservationists, students, and advocates.</li>
                </ul>
                {/* <p className="article-par">
                  That evening, the community engagement took place in Abia at
                  6:00 PM. Despite a power outage during the setup, the
                  committed WHRO team hired a generator to power the equipment.
                  Children and adults gathered at the park to participate in the
                  engagement. Dr. Agyekumhene gave a presentation on sanitation
                  and its impact on marine life and human health. He suggested
                  ways the community could help address those activities that
                  cause ocean pollution, emphasizing proper waste disposal to
                  improve community sanitation. Audiovisuals were used to
                  enhance understanding. The Q&A session allowed community
                  members to express their concerns and share ideas for reducing
                  plastic waste. Dr. Agyekumhene invited everyone to the beach
                  cleanup scheduled for the next day at 7:00 AM.
                </p> */}
                <p className="article-par mt-6">
                The 43rd International Sea Turtle Symposium promises to be an enriching experience for anyone passionate about sea turtle conservation, offering vital opportunities for knowledge-sharing, networking, and collective action. More than just a gathering, this symposium is a movement aimed at strengthening global efforts to protect sea turtles. Whether you're an experienced conservationist or a student starting a career in sea turtle conservation, the 43rd International Sea Turtle Society Symposium provides the opportunity for you to be part of a larger community and make a meaningful impact.
                </p>
                <div className="flex justify-center pt-10">
                  <a href="https://www.ists-symposium43.org/" target="_blank">
                    <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                      Register now
                    </button>
                    </a>
                </div>
              </div>

              <div className="flex pt-4 gap-x-5 text-green-900 md:mt-10">
                <a href="https://www.facebook.com/whroghana/" target="_blank">
                  <img alt="Facebook icon" className="h-5" src={facebook} />
                </a>
                <a href="https://twitter.com/WHRO_GHANA" target="_blank">
                  <img alt="Twitter icon" className="h-5" src={twitter} />
                </a>
                <a href="https://www.instagram.com/whroghana/" target="_blank">
                  <img alt="Instagram icon" className="h-5" src={instagram} />
                </a>
              </div>
              <div className="comments mt-20">
                <h1 className="text-2xl mb-5">Leave a comment</h1>
                <form>
                  <textarea
                    className="w-full h-40 placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                    placeholder="Comments*"
                  ></textarea>
                  <div className="flex box-border gap-x-10">
                    <div className="w-1/2">
                      <input
                        type="text"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Full Name*"
                      />
                    </div>
                    <div className="w-1/2">
                      <input
                        type="email"
                        className="w-full placeholder-black px-4 p-2 rounded-3xl border border-gray-400 mb-7"
                        placeholder="Email*"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rounded-3xl bg-green-800 text-white py-2 px-7 mt-6"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full xl:w-[30%] xl:pr-10 box-border">
              {/* <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog7">
                  <div className="w-1/4">
                    <img alt="Anyanui Mangrove Market" src={sthumb5} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s
                      Visit to Anyanui Mangrove Market
                    </h4>
                  </div>
                </Link>
              </div> */}
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog4">
                  <div className="w-1/4">
                    <img alt="Sea Turtles" src={sthumb4} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      WHRO&apos;s Ongoing Efforts to Conserve Ghana&apos;s Sea
                      Turtles
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog3">
                  <div className="w-1/4">
                    <img alt="Sea Turtle Conservation in Ghana" src={sthumb3} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Experts Discuss Urgent Need for Sea Turtle Conservation in
                      Ghana
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog2">
                  <div className="w-1/4">
                    <img alt="Ms. Delali Betty Dordzi Speaks" src={sthumb2} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Ms. Delali Betty Dordzi Speaks on ‘The Coastal & Marine
                      Resources Conservation’
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog5">
                  <div className="w-1/4">
                    <img alt="Anyanui Mangrove Market" src={sthumb5} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      Exploring Ghana&apos;s Coastal Treasures: WHRO&apos;s
                      Visit to Anyanui Mangrove Market
                    </h4>
                  </div>
                </Link>
              </div>
              <div className="flex border-b pb-5 mb-5">
                <Link className="flex" to="/blog">
                  <div className="w-1/4">
                    <img alt="2nd Ghana National Sea Turtle Conference 2023" src={sthumb1} />
                  </div>
                  <div className="w-3/4 pl-5">
                    <div className="flex items-center mb-2">
                      <span className="bg-green-200 text-green-800 p-0.5 px-2 rounded">
                        MEDIA
                      </span>
                      <h6 className="ml-5">2023</h6>
                    </div>
                    <h4 className="text-lg font-semibold">
                      2nd Ghana National Sea Turtle Conference 2023
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog8;
