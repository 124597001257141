import guidelines from "/images/guidelines.jpg";
import resilient1 from "/images/resilient-1.jpg";
import degradation from "/images/degradation.jpg";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function ResearchDisplay() {
  useEffect(() => {
    Aos.init({ once: true });
  }, []);
  return (
    <>
      <div id="programmes" className="py-14 md:py-16 lg:py-32">
        <div className="container">
          <div data-aos="fade-down">
            <h6 className="text-center text-xs md:text-sm mini-heading mb-1">
              INSIGHTS AND DISCOVERIES
            </h6>
            <h3
              data-aos="fade-up"
              data-aos-delay="100"
              className="text-center main-heading font-medium text-2xl md:text-4xl mb-14"
            >
              Our Research & Publications
            </h3>
          </div>
          <div className="flex flex-col xl:flex-row gap-y-10 xl:gap-y-0">
            <div className="w-full flex flex-col md:flex-row gap-x-8 gap-y-10 flex-wrap box-border justify-center">
              <div data-aos="zoom-in" className="programme-card bg-green-200">
                <Link
                  to="/publications/1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-shadow">
                    <img alt="Guiding Framework to Address Sea Turtle Poaching" src={guidelines} />
                    <div className="px-6 bg-green-200 py-6">
                      <div className="flex items-center mb-2">
                        <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                          PUBLICATION
                        </span>
                        <h6 className="ml-5">2023</h6>
                      </div>
                      <h4 className="text-lg md:text-xl font-semibold">
                        Guiding Framework to Address Sea Turtle Poaching in a
                        Local Community: A Case Study from Ghana
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div data-aos="zoom-in" className="programme-card bg-green-200">
                <Link
                  to="/publications/2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-shadow h-full">
                    <img alt="Resilient lagoons?" src={resilient1} />
                    <div className="px-6 bg-green-200 py-6">
                      <div className="flex items-center mb-2">
                        <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                          PUBLICATION
                        </span>
                        <h6 className="ml-5">2022</h6>
                      </div>
                      <h4 className="text-lg md:text-xl font-semibold">
                        Resilient lagoons? Climate change, sustainability and
                        adaptation
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div data-aos="zoom-in" className="programme-card bg-green-200">
                <Link
                  to="/publications/3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-shadow h-full">
                    <img alt="Mangrove Degradation" src={degradation} />
                    <div className="px-6 bg-green-200 py-6">
                      <div className="flex items-center mb-2">
                        <span className="bg-green-800 text-white p-0.5 px-2 rounded">
                          PUBLICATION
                        </span>
                        <h6 className="ml-5">2022</h6>
                      </div>
                      <h4 className="text-lg md:text-xl font-semibold">
                        Mangrove Degradation and Management Practices along the
                        Coast of Ghana
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center mt-14 md:mt-16">
            <Link to="/publications-list">
              <button className="bg-green-900 py-3 px-12 text-white text-lg rounded-3xl hover:bg-green-700 hover:text-footer-white transition duration-300 ease-in-out">
                View All Publications
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResearchDisplay;
